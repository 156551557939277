<template>
  <div
    id="admin-real-estate-develoment-view"
    class="real-estate-develoment-view"
  >
    <v-row no-gutters>
      <v-col md="12">
        <real-estate-development-edit-form />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import DefaultLayout from "@/components/layouts/DefaultLayout.vue";

export default {
  components: {
    RealEstateDevelopmentEditForm: () =>
      import("@/components/real-estate-development/RealEstateEditTwo.vue")
  },
  created() {
    this.$emit(`update:layout`, DefaultLayout);
  }
};
</script>

<style></style>
